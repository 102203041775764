@use '../../../../styles/client-animations';

.overlay {
  height: 100%;
  width: 100%;
  padding: 1rem;

  .close-button + * {
    animation-name: grow;
    animation-duration: 0.3s;
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: larger;

    z-index: 1;
  }
}
