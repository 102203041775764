.mobile-nav-link-icon {
  .jtjs-text {
    font-size: 0.8rem;
  }
  .jtjs-icon {
    font-size: 2rem;
  }

  @media only screen and (max-width: 320px) {
    .jtjs-text {
      font-size: 0.7rem;
    }
    .jtjs-icon {
      font-size: 1.5rem;
    }
  }
}
