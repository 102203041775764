.expandable-image-button {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 10px;
}

.expandable-image,
.expanded-image {
  border-radius: 10px;
}

.expandable-image {
  cursor: zoom-in;
}

.expanded-image {
  cursor: zoom-out;
}
