@keyframes undulate-drop-shadow {
  0% {
    filter: drop-shadow(0 0 0.2em var(--jtjs-theme-background));
  }
  50% {
    filter: drop-shadow(0 0 0.3em var(--jtjs-theme-background));
  }
  100% {
    filter: drop-shadow(0 0 0.2em var(--jtjs-theme-background));
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes come-into-focus {
  from {
    filter: blur(25px) opacity(0.1);
  }
  to {
    filter: blur(0) opacity(1);
  }
}

// TODO: Somehow want to construct this animation via a function or something...
// Don't like duplicating this for the sake of contrasted elements, but I don't
// want to get rid of their drop shadow because that's the best way to get what
// I want for that class.
@keyframes come-into-focus-contrasted-element {
  from {
    filter: blur(25px) opacity(0.1)
      drop-shadow(0 0 0em var(--jtjs-theme-background));
  }
  to {
    filter: blur(0) opacity(1)
      drop-shadow(0 0 0.1em var(--jtjs-theme-background));
  }
}
