.overlay-manager {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;

  transition: background-color 0.2s ease;
}
