%font-title {
  font-family: Raleway, Arial, sans-serif;
  // font-size: 2em;
}
%font-body {
  font-family: Lato, sans-serif;
  font-size: 1em;
}
%font-other {
  font-family: 'Roboto Condensed', sans-serif;
}
