@import 'lightroom-audio-controls';

#lightroom-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .jtjs-text,
  .jtjs-heading,
  .jtjs-icon {
    color: white;
  }

  opacity: 1;

  transition: opacity 0.2s ease;

  #fullscreen-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    font-size: x-large;
    position: absolute;
    top: 0.5rem;
    right: 0.3rem;

    transition: opacity 0.2s ease;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .lightroom-audio-controls {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &.hidden {
    opacity: 0;
    cursor: none !important;

    * {
      pointer-events: none;
    }
  }
}
