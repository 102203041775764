.about-card {
  overflow: hidden;

  .heading {
    width: 100%;
    height: 500px;
    border-radius: 0;
    overflow: hidden;

    .portrait {
      width: 100%;
    }
  }
}
