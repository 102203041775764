@use '../../../../styles/client-vars';

#site-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  bottom: 0;

  box-shadow: 0 0.1rem inset client-vars.$navBorderColor;
  background-color: var(--jtjs-theme-foreground);

  transition: height 0.2s ease, padding 0.2s ease;

  &.hidden {
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  @media only screen and (max-width: 767px) {
    // Give the footer additional bottom padding when the mobile nav would be
    // visible so the mobile nav doesn't cover up the footer.
    padding-bottom: calc(1rem + client-vars.$siteNavHeight);
  }
}
