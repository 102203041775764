@use './app/styles/client-animations';
@use './app/styles/placeholders/fonts';
@use './app/styles/breakpoints';

@import '@jtjs/core-theme';

@import url('https://fonts.googleapis.com/css?family=Lato|Raleway|Roboto+Condensed');

@import './app/components/shared/shared';

@import './app/components/pages/home/home';
@import './app/components/pages/games/games';
@import './app/components/pages/about/about';
@import './app/components/pages/legal/legal';
@import './app/components/pages/lightroom/lightroom';

html,
body {
  display: block;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 100%;

  * {
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  @extend %font-body;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #page-content {
    flex: 1;
  }
}

#page-content {
  width: 100%;
  max-width: 2048px;
  margin: 0 auto;
  padding: 1rem;
  display: block;

  transition: padding 0.5s ease;

  * {
    animation-name: come-into-focus;
    animation-duration: 0.5s;
  }

  @media only screen and (max-width: breakpoints.$sm) {
    padding: 0.5rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--jtjs-theme-text);
  @extend %font-title;
}

h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.8em;
}
h4 {
  font-size: 1.6em;
}
h5 {
  font-size: 1.4em;
}
h6 {
  font-size: 1.3em;
}

#background-canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
}

.jtjs-button {
  .jtjs-icon {
    color: var(--jtjs-theme-buttonText);
  }

  &:disabled {
    opacity: 0.6;
  }
}

a.jtjs-button {
  text-decoration: none;
}

.contrasted-element {
  filter: drop-shadow(0 0 0.1em var(--jtjs-theme-background));
  animation-name: come-into-focus-contrasted-element !important;
}

.override-button {
  border: none;
  background-color: transparent;
  text-align: left;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: var(--jtjs-theme-background-darkened);
}

::-webkit-scrollbar-thumb {
  background-color: var(--jtjs-theme-foreground-lightened);
}
