.image-collection {
  width: 100%;

  .image-container {
    flex-grow: 1;
    flex-basis: 40%;

    img {
      max-width: 100%;
    }
  }
}
