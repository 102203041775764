@import './about-card';
@import './portrait';

#about-page {
  .preview-image-card-container {
    .preview-image-card {
      background-position: top left;
    }

    @media only screen and (min-width: 760px) {
      max-width: 50%;
    }
  }
}
