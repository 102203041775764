@use 'sass:math';
@use '../../../../styles/client-mixins';
@use '../../../../styles/client-vars';

.site-nav {
  min-height: client-vars.$siteNavHeight;
  width: 100%;
  background-color: var(--jtjs-theme-foreground);
  z-index: 99;

  transition: height 0.2s ease, min-height 0.2s ease;

  .active {
    box-shadow: 0 -0.4em inset var(--jtjs-theme-button);
  }

  a {
    text-decoration: none;

    transition: box-shadow 0.2s ease;
  }

  &#desktop-nav {
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0 -0.1rem inset client-vars.$navBorderColor;

    .ucg-logo {
      @include client-mixins.get-logo-dimensions(3rem);
      margin-left: 1rem;

      .logo-image {
        fill: var(--jtjs-theme-text);
      }
    }

    a:not(#logo-link) {
      color: var(--jtjs-theme-text);
      text-decoration: none;
      padding: 0 1.5rem;
      height: 100%;
      line-height: client-vars.$siteNavHeight;
      font-size: larger;

      &:hover,
      &:focus {
        &:not(.active) {
          box-shadow: 0 -0.3rem inset var(--jtjs-theme-button);
        }
      }
    }
  }
  &#mobile-nav {
    position: fixed;
    bottom: 0;
    left: 0;

    box-shadow: 0 0.1rem inset client-vars.$navBorderColor;

    #more-nav-button {
      cursor: pointer;
      background: transparent;
      border: none;
    }

    .main-paths {
      height: client-vars.$siteNavHeight;

      box-shadow: 0 -0.1rem inset client-vars.$navBorderColor;

      .mobile-nav-link-icon {
        height: 100%;
        flex: 1;
      }
    }
    .other-paths {
      height: 0;
      overflow: hidden;

      transition: height 0.3s ease-in-out;

      .mobile-nav-link-icon {
        flex: 1;
        flex-basis: 50%;
        height: 15rem;
        width: 15rem;
        border: 1px solid client-vars.$navBorderColor;

        .jtjs-icon {
          font-size: 2.5rem;
        }
        .jtjs-text {
          font-size: 1rem;
        }

        @media only screen and (max-width: 400px) {
          height: 10rem;
          width: 10rem;
        }
      }

      &.expanded {
        display: inline-block;
        overflow-y: auto;
        height: 49.5vh;

        @media only screen and (max-height: 500px) {
          height: calc(100vh - client-vars.$siteNavHeight);
        }
      }
    }
  }

  &.hidden {
    min-height: 0;
    height: 0;
    overflow: hidden;
  }
}
