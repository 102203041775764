.lightroom-audio-controls {
  $buttonColor: rgb(58, 58, 58);
  $buttonTextColor: white;
  width: 100%;

  .control-buttons {
    .play-button {
      height: 4rem;
      width: 4rem;
    }
    .song-selection-button {
      transform: rotate(0);

      transition: transform 0.2s ease;

      &.expanded {
        transform: rotate(45deg);
      }
    }

    button {
      border: none;
      color: $buttonTextColor;
      background-color: $buttonColor;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      font-size: larger;
      opacity: 0.8;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: opacity 0.2s ease;

      &:hover,
      &:focus-visible {
        opacity: 1;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
      }
    }
  }

  .track-scrubber-container {
    .jtjs-text {
      margin-bottom: 0;
    }
  }

  .track-list {
    max-height: 50vh;
    overflow: auto;
    margin-top: 1rem;

    button {
      font-size: larger;
      border: none;
      cursor: pointer;
      @extend %font-body;
      color: $buttonTextColor;
      background-color: $buttonColor;
      padding: 0.5rem;
      border-radius: 5px;

      opacity: 0.8;

      transition: opacity 0.2s ease;

      &:hover,
      &:focus-visible {
        opacity: 1;
      }
    }
  }
}
