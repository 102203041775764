.background-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;

  width: 100%;

  @mixin bar {
    flex: 1;
    content: '';
    height: 0.05em;
    width: 0%;
    display: block;

    background-color: var(--jtjs-theme-text);
  }

  &::before {
    @include bar;
  }
  &::after {
    @include bar;
  }
}
