@use '../../../styles/breakpoints';

.content-card {
  margin: 1rem auto;
  color: var(--jtjs-theme-text);

  transition: width 0.5s ease;

  @media only screen and (min-width: breakpoints.$md) {
    width: 100%;
  }
  @media only screen and (min-width: breakpoints.$lg) {
    width: 80%;
  }
  @media only screen and (min-width: breakpoints.$xl) {
    width: 60%;
  }
}
