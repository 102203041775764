@use '../../../styles/client-mixins';

#legal-page {
  h5 {
    margin-top: 1rem;
  }
  h6 {
    margin: 1rem 0;
  }

  .ucg-logo {
    @include client-mixins.get-logo-dimensions(8rem);

    .logo-image {
      fill: var(--jtjs-theme-text);
    }
  }
}
