.preview-image-card-container {
  display: flex;
  text-decoration: none;
  border-radius: 25px;

  flex: 1 1 350px;
  min-height: 50vh;
  width: 100%;

  &:hover,
  &:focus {
    .jtjs-background-image-card {
      $raisedAmount: 0.5rem;
      transform: translateY(-$raisedAmount);
      box-shadow: 0 $raisedAmount var(--jtjs-theme-foreground);

      &.with-link {
        box-shadow: 0 $raisedAmount var(--jtjs-theme-button);
      }
    }
  }

  @media only screen and (min-width: 760px) {
    .jtjs-background-image-card {
      .details-container {
        opacity: 0;

        transition: opacity 0.2s ease;
      }

      .jtjs-inner-shadow {
        opacity: 0;

        transition: opacity 0.2s ease;
      }
    }

    &:hover,
    &:focus {
      .jtjs-background-image-card {
        .details-container {
          opacity: 1;
        }

        .jtjs-inner-shadow {
          opacity: 1;
        }
      }
    }
  }

  .preview-image-card {
    display: block;
    background-position: center;

    transition: box-shadow 0.2s ease, transform 0.2s ease;

    .jtjs-heading {
      text-align: center;
    }

    .details-container {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: center;

      opacity: 0;
    }

    .jtjs-inner-shadow {
      opacity: 0;
    }
  }
}
