.theme-picker {
  .theme-selection-button {
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;

    transition: background-color 0.2s ease, border 0.2s ease;

    &:hover,
    &:focus-visible {
      background-color: var(--jtjs-theme-button);

      .jtjs-heading {
        color: var(--jtjs-theme-buttonText);
      }
    }
  }
}
