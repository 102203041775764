.product-card {
  .supported-device-badge {
    font-size: larger;
    border-radius: 50%;
    padding: 1rem;

    box-shadow: 0 0 0.5rem 0.3rem var(--jtjs-theme-foreground-darkened);
    background-color: var(--jtjs-theme-foreground-lightened);
  }
  section:last-child {
    margin-bottom: 1.5rem;
  }
}
